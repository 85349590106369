<template>
  <select class="form-control" :value="servicio" @change="$emit('update:servicio', $event.target.value)" required>
    <option value="" selected disabled>{{placeholder}}</option>
    <option value="0" v-if="all">Todos</option>
    <option v-for="_servicio in servicios" :key="_servicio.id_servicio" :value="_servicio.id_servicio">{{_servicio.nombre}}</option>
  </select>
</template>

<script>
import {defineComponent, ref} from "vue";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";
import useAuth from "@/providers/auth";

export default defineComponent({
  name: "SelectServicio",
  props: {
    servicio: {
      type: String,
      default: ''
    },
    all: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Seleccionar Servicio'
    }
  },
  setup(){
    const { loading, make } = useApi();
    const servicios = ref([]);
    const {isCliente} = useAuth();

    async function getServicios(){
      loading.message = 'Obteniendo servicios';
      let {data} = await make('generales/get-servicios', {});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          servicios.value = data.servicios;
        }
      }
    }

    getServicios();

    return {servicios, isCliente};
  }
})
</script>

<style scoped>

</style>